video {
    height: 300px;
    width: 100%;
    min-block-size: 250px;
    max-width: 800px;
}

.slides {
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    padding: .5rem;
    margin: 1rem auto 0 auto;
}

.slides img {
    cursor: pointer;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    background: hsl(0, 0%, 80%);
    max-width: 500px;
    border-radius: 10px;
    margin: 0 auto;
}

.animate {
    opacity: 0;
    transition-duration: 1s ease;
}

.animate.true {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1);
}

.active-video {
    display: flex;
    align-items: center;
}

.active-video button {
    height: 10px;
    width: 10px;
    border: none;
    border-radius: 50%;
    margin: 0 4px;
}

@media(min-width: 850px) {
    .carousel {
        display: none;
    }

    .slides {
        display: none;
    }
}