.footer__contact {
    background: hsl(213, 92%, 25%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem clamp(1rem, 7vw, 8rem) 2rem clamp(1rem, 7vw, 8rem);
    gap: 3rem;
}

.footer__contact a {
    padding: 1rem;
    display: block;
    display: flex;
    flex: 1;
    flex-basis: 250px;
    max-width: 290px;
    border: 2px solid white;
    border-radius: 12px;
    color: white;
}

.footer__contact p {
    font-size: .95rem;
    margin-left: 1rem;
}

.footer__contact span {
    font-size: .8rem;
}

footer a {
    color: white;
}

.footer__quick__links {
    background: hsl(213, 92%, 25%);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2rem clamp(1rem, 7vw, 8rem);
    gap: 2rem;
}

.footer__quick__links>ul {
    flex-grow: 1;
    flex-basis: 200px;
    width: fit-content;
}

.footer__quick__links h3 {
    color: white;
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.footer__quick__links a {
    color: white;
    font-size: .9rem;
    font-weight: 400;
    margin: .3rem 0;
    display: inline-block;
}

.footer__social__links {
    display: flex;
    gap: 1rem;
    margin-top: 0rem;
}

.footer__social__links img {
    height: 30px;
    /* background: white;
    border-radius: 50%;
    padding: 6px; */
    /* color: #ffffff; */
}

.footer__policy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    background: white;
    padding: .3rem clamp(1rem, 7vw, 6rem);
    font-family: "PT Sans", sans-serif;
    font-size: .75rem;
}

.footer__policy ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.footer__policy a {
    color: hsl(231, 51%, 33%);
    display: inline-block;
    font-weight: 400;
    font-size: .9rem;
    margin: .7rem 0;
}

.footer__policy p {
    color: hsl(231, 51%, 33%);
    margin: 0;
    padding: 0 .5rem;
    font-size: .75rem;
    font-weight: 400;
}