.home__priority {
    padding: 4rem clamp(1rem, 4vw, 8rem);
    text-align: center;
    position: relative;
    overflow: hidden;
}
.home{
    font-family: "PT Sans", sans-serif;
}

.priority-bg1 {
    pointer-events: none;
    position: absolute;
    left: -2px;
    height: 240px;
    top: -45px;
}

.priority-bg2 {
    pointer-events: none;
    position: absolute;
    left: 17px;
    height: 7rem;
    top: 38px;
}

.priority-bg3 {
    pointer-events: none;
    position: absolute;
    right: -37px;
    height: 7rem;
    bottom: 6px;
    transform: rotate(-75deg);
}

.home__hospital__welcome-bg1 {
    pointer-events: none;
    position: absolute;
    max-width: 500px;
    max-height: 400px;
    left: -4%;
    top: 0;
}

.home__priority h2 {
    text-transform: uppercase;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: hsl(213, 92%, 25%);
}

.home__priority h2 span {
    color: black;
}

.home__priority p {
    color: hsl(0, 0%, 38%);
    max-width: 800px;
    margin: auto;
}

.home__hospital__welcome {
    background: hsl(0, 0%, 93%);
    padding: 3rem clamp(1rem, 7vw, 8rem);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;
    position: relative;
    overflow: hidden;
}

.home__hospital__welcome h2 {
    text-transform: uppercase;
    color: hsl(213, 92%, 25%);
}

.home__hospital__welcome span {
    color: black;
}

.home__hospital__welcome p {
    color: hsl(0, 0%, 38%);
    font-size: 1.2rem;
    line-height: 2rem;
}

.home__hospital__welcome>figure {
    flex-basis: 300px;
    flex-grow: 1;
    position: relative;
}

.home__hospital__welcome>figure img:nth-child(1) {
    width: 100%;
    padding: 0;
}

.welcome__overlay {
    position: absolute;
    transform: translate(50%, 40%);
    max-width: 100%;
    pointer-events: none;
}

.home__hospital__welcome>div {
    flex-grow: 1;
    flex-basis: 300px;
}

.home__hospital__welcome button {
    color: hsl(213, 92%, 25%);
    border: 2px solid hsl(213, 92%, 25%);
    border-radius: 25.5px;
    padding: .6rem 2.5rem;
    font-size: 1rem;
    margin-top: 1rem;
    transition: all .3s ease;
}

.home__hospital__welcome button:hover {
    color: white;
    background: hsl(213, 92%, 25%);
}

.home__online {
    display: flex;
}

.home__online p {
    font-size: .8rem;
    font-weight: 700;
    margin-left: 6px;
    color: black;
}

.home__online img {
    height: 20px;
}

.home__online div {
    display: flex;
    align-items: center;
    margin-right: 2rem;
}

.home__goals {
    margin: 1rem 0;
    padding: 1rem 1rem;
    overflow: hidden;
    position: relative;
}

.home__goals p {
    max-width: 400px;
}

.home__goals__vision,
.home__goals__mission {
    gap: 3rem;
    margin: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.home__goals__mission>img,
.home__goals__vision>img {
    text-align: center;
    flex-basis: 300px;
    flex-grow: 1;
    width: 100%;
    max-width: 400px;
}

.home__goals__vision div,
.home__goals__mission div {
    flex-basis: 300px;
    max-width: 400px;
    flex-grow: 1;
}

.home__goals__vision div img,
.home__goals__mission div img {
    height: 4.5rem;
}

.home__goals__vision div h3,
.home__goals__mission div h3 {
    color: hsl(213, 92%, 25%);
    font-size: 2rem;
    margin: 1rem 0;
}

.home__goals__mission p {
    margin-left: auto;
    color: hsl(0, 0%, 38%);
}

.home__goals__vision p {
    color: hsl(0, 0%, 38%);
}

.mission-bg {
    position: absolute;
    pointer-events: none;
    left: -2px;
    bottom: -15%;
    z-index: -1;
    opacity: .8;
}

.vision-bg {
    position: absolute;
    pointer-events: none;
    top: -1%;
    right: -2px;
    z-index: -1;
    opacity: .8;
}

.vision-bg-border {
    position: absolute;
    pointer-events: none;
    top: -4%;
    right: -4px;
    z-index: -1;
    opacity: .5;
}


.home__services {
    background: hsl(213, 92%, 25%);
    padding: 3rem clamp(1rem, 7vw, 8rem);
    text-align: center;
    position: relative;
    overflow: hidden;
}

.services__bg {
    position: absolute;
    top: -2px;
    right: -2px;
}

.home__services h2 {
    color: white;
    text-transform: uppercase;
}

.home__services p {
    color: white;
}

.home__service__type {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;
}

.service__types {
    border-radius: 20px;
    background: white;
    flex: 1;
    flex-basis: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12rem;
    max-width: 12rem;
    height: 12rem;
    padding: 3rem .5rem;
}

.service__types h4 {
    text-transform: uppercase;
    font-size: .9rem;
    font-weight: 500;
    margin-top: 1rem;
}

.service__types img {
    height: 3rem;
}

.home__appiontment {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: hsl(0, 0%, 89%);
}

.home__appiontment p {
    color: hsl(0, 0%, 38%);
}

.home__appiontment h2 {
    text-transform: uppercase;
    color: hsl(213, 92%, 25%);
}

.home__appiontment span {
    color: black;
}

.home__appiontment .div {
    flex-basis: 400px;
    flex-grow: 1;
    min-height: 100%;
    padding: 0 0 0 2rem;
    padding: 1rem clamp(1rem, 7vw, 8rem) 1rem clamp(1rem, 4vw, 8rem);
}

.home__appiontment .img-cover {
    flex: 1;
    flex-basis: 400px;
}

.home__appiontment .img-cover img {
    width: 100%;
    object-fit: cover;
}

.home__appiontment input {
    font-size: 1rem;
    color: hsl(0, 0%, 38%);
    border: none;
    border-bottom: 1px solid hsl(0, 0%, 38%);
    background: transparent;
    margin: .7rem 0;
    padding: .5rem 0;
    width: 80%;
}

.home__appiontment select {
    border: none;
    border-bottom: 1px solid hsl(0, 0%, 38%);
    margin: .7rem 0;
    color: hsl(0, 0%, 38%);
    padding: .5rem 0;
    width: clamp(200px, 100%, 350px);
    font-size: 1rem;
    background: transparent;
    min-width: 80%;
    max-width: 80%;
}

.home__appiontment option {
    padding: .5rem 0;
    border: none;
    font-size: 1rem;
}

.date {
    padding-top: 0 !important;
}

.time {
    padding-top: 0 !important;
}

.home__appiontment label {
    display: block;
    margin-bottom: -30px;
    color: hsl(0, 0%, 38%);
}

select:focus {
    outline: none;
}

.home__appiontment .appiontment-submit {
    font-weight: 500;
    font-size: 1rem;
    padding: .5rem 1.5rem;
    margin: 1rem 0;
    background: transparent;
    border-radius: 30px;
    border: 1px solid black;
    transition: all .3s ease;
}

.home__appiontment button:hover {
    background: black;
    color: white;
}

.home__testimony {
    padding: 3rem 2rem;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.home__testimony h2 {
    font-size: 2rem;
    color: hsl(213, 92%, 25%);
    margin-bottom: 1rem;
}

.home__testimony .testimonial {
    width: 100%;
    max-width: 500px;
}

.testimony-bg1 {
    position: absolute;
    left: 0;
    bottom: 10%;
    z-index: -1;
    pointer-events: none;
}

.testimony-bg2 {
    position: absolute;
    right: 0;
    bottom: 20%;
    z-index: -1;
    pointer-events: none;
}