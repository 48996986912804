@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.moves {
    transform: scale(0.5);
    transition: transform 300ms;
    opacity: 0.5;
    pointer-events: none;
}

.activemoves {
    transform: scale(1.1);
    opacity: 1;
    pointer-events: all;
}

.testifiers {
    max-width: 1000px;
    position: relative;
    padding: 3rem clamp(1rem, 6vw, 8rem);
    margin: auto;
    padding-bottom: 2rem;
    padding-top: 1rem;
}

.test {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
    pointer-events: none;
}

.activeTest {
    transform: scale(1.1);
    opacity: 1;
    pointer-events: all;
}

.reveal {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    bottom: -3rem;
    width: fit-content;
}

.nexts {
    right: 35%;
    /* top: 2rem; */
}

.prevs {
    left: 35%;
    /* top: 50%; */
}

video {
    height: 400px;
    max-width: 300px;
}

.next-f {
    right: 0%;
    top: 50%;
}

.prev-f {
    left: 0%;
    top: 50%;
}

.testifiers video {
    height: 400px;
    width: clamp(230px, 100vw, 500px);
    min-width: 250px;
}

.active-vid {
    display: flex;
    align-items: center;
}

.active-vid button {
    height: 10px;
    width: 10px;
    border: none;
    border-radius: 50%;
    margin: 0 4px;
}

.active-vid-dot {
    background: hsl(213, 92%, 25%);
}

@media (max-width: 600px) {
    .nexts {
        right: 15%;
        /* top: 2rem; */
    }

    .prevs {
        left: 15%;
        /* top: 50%; */
    }
}

@media (max-width: 850px) {
    .testifiers {
        display: none;
    }
}