.cookie-container {
    position: relative;
    padding-top: 4.5rem;
}

.cookies-consent {
    color: white;
    position: fixed;
    z-index: 1;
    width: 100%;
    bottom: 0;
    background: hsl(213, 92%, 25%, .9);
    backdrop-filter: blur(3px);
}

.cookies-consent>div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: .6rem clamp(1rem, 7vw, 8rem);
    margin: auto;
    max-width: 850px;
}

.cookies-consent p {
    font-size: .9rem;
    margin: 0 auto;
    max-width: 400px;
}

.cookie-btn {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    gap: 1rem;
}

.cookie-btn button {
    color: hsl(213, 92%, 25%);
    background: white;
    border: none;
    padding: .5rem 1.5rem;
    font-size: 1rem;
    font-family: inherit;
    border-radius: 5px;
}