.slideshow {
    margin: 0;
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 600px;
    backdrop-filter: blur(3px);
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(50%);
    transition: all .5s ease;
    overflow: hidden;
}

.slider-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-overlay {
    background: hsl(0, 0%, 0%, .3);
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
}

.home__welcome img {
    margin-left: -2rem;
    height: 3.5rem;
    pointer-events: none;
}

.fade-bg {
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    /* width: clamp(500px, 70vw, 730px); */
    pointer-events: none;
    object-fit: cover;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    opacity: 0;
    animation: slide-up ease .5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    visibility: hidden;
    transition: all .4s ease;
    padding: 3rem clamp(1rem, 6vw, 8rem);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.intro__contact {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.home__welcome {
    flex-grow: 1;
    flex-basis: 700px;
    /* background-color: hsl(0, 0%, 20%, .3); */
    max-width: fit-content;
}

.content {
    opacity: 0;
    animation: slide-up 1s ease .5s;
    animation-fill-mode: forwards;
    visibility: hidden;
    transition: all .4s ease;
    transform: scale(.8);
}

.content>* {
    color: white;
    margin-bottom: 1rem;
}

.content h1 {
    font-size: 1.3rem;
    margin: 1rem 0;
    text-transform: uppercase;
    transition: all .4s ease;
}

.content p {
    font-size: 2.2rem;
    transition: all .4s ease;
    max-width: 600px;
    transition: all .4s ease;
    font-family: 'PT Sans';
    margin: 1rem 0;
    line-height: 2.2rem;
}

.home__welcome button {
    border: 2px solid white;
    background: transparent;
    color: white;
    font-size: 1rem;
    padding: .6rem 1rem;
    border-radius: 30px;
    margin: 1.5rem 0;
    transition: all .3s ease;
}

.home__welcome button:hover {
    background: white;
    color: hsl(213, 92%, 25%);
}

.current {
    opacity: 1;
    transform: translateX(0);
}

.current .content {
    opacity: 1;
}

.active-btn {
    position: absolute;
    z-index: 3;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
}

.active-btn button {
    height: 10px;
    width: 10px;
    border: none;
    border-radius: 50%;
    margin: 0 4px;
}

.active-dot {
    background: hsl(213, 92%, 25%);
}

@keyframes slide-up {
    0% {
        visibility: visible;
        left: 13rem;
        opacity: 0;
    }

    100% {
        visibility: visible;
        left: 0;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slide-up2 {
    0% {
        visibility: visible;
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
}

@media(max-width: 890px) {
    .content {
        opacity: 0;
        animation: slide-up2 1s ease .5s;
        animation-fill-mode: forwards;
        visibility: hidden;
        transition: all .4s ease;
        transform: scale(.8);
    }

    .intro__contact {
        flex-direction: row;
    }
}

@media(max-width: 430px) {
    .slideshow {
        height: 630px;
    }
}