* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: inherit;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}

button:focus,
input:focus,
textarea:focus {
  outline: none;
}

button {
  cursor: pointer;
}

h1,
h2,
h3,
h4 {
  font-family: inherit;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  font-family: inherit;
}

p {
  font-size: 1.05rem;
  margin: .7rem 0;
  line-height: 1.6rem;
  font-weight: 500;
  font-family: "PT Sans", sans-serif;
}

.top-scroll {
  position: fixed;
  z-index: 5;
  bottom: 10%;
  right: 0;
  border: none;
  padding: .5rem;
  width: 40px;
  font-size: 1.5rem;
  background: hsl(213, 92%, 25%, .6);
  color: white;
  cursor: pointer;
}

body {
  max-width: 1600px;
  margin: auto;
}

nav {
  max-width: 1600px;
}

.preloader {
  height: 100vh;
  width: 100vw;
  transition: all .3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background: hsl(213, 92%, 25%, .6);
}