header {
    position: relative;
    z-index: 1000;
    padding-bottom: 4.8rem;
    font-family: "PT Sans", sans-serif;
}

.header__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    padding: .5rem clamp(1rem, 4vw, 8rem);
    z-index: 900;
    background: white;
    box-shadow: 0px 0px 20px hsl(213, 92%, 25%, .6);
}

.header__links__toggler {
    display: none;
}

.header__logo {
    height: 4rem;
}

.header__links {
    display: flex;
}

.active {
    position: relative;
    transition: all .3s ease;
    font-weight: 500;
}

.active::after {
    content: '';
    height: 3px;
    background: hsl(213, 92%, 25%);
    position: absolute;
    bottom: -1rem;
    left: 20%;
    right: 20%;
    display: block;
    border-radius: 10px;
    animation: activeTab;
    animation-duration: .3s;
}

@keyframes activeTab {
    from {
        transform: scaleX(0);
        opacity: .5;
    }

    to {
        transform: scaleX(1);
        opacity: 1;
    }
}

.header__links .pages__links {
    display: flex;
}

.header__links .pages__links a {
    font-size: 1rem;
    font-weight: 400;
    font-family: "PT Sans", sans-serif;
    margin: 0 1rem;
    color: hsl(213, 92%, 25%);
    display: inline-block;
    transition: all .3s ease;
}

.header__btn__links {
    display: flex;
    gap: 1.5rem;
    font-size: .85rem;
    font-weight: 400;
}

.header__appointment__btn {
    color: hsl(213, 92%, 25%);
    font-weight: 400;
    border: 2px solid hsl(213, 92%, 25%);
    background: white;
    border-radius: 30px;
    padding: .6rem 1rem;
    transition: all .3s ease;
    width: fit-content;
}

.header__appointment__btn:hover {
    background: hsl(213, 92%, 25%);
    color: white;
}
.header__covid__btn:hover {
    color: hsl(213, 92%, 25%);
    background: white;
    border: 2px solid hsl(213, 92%, 25%);
}

.header__covid__btn {
    border-radius: 30px;
    font-weight: 400;
    border: 2px solid hsl(213, 92%, 25%);
    background: hsl(213, 92%, 25%);
    color: white;
    transition: all .3s ease;
    padding: .6rem 1rem;
    display: flex;
    align-items: center;
    width: fit-content;
}

.header__covid__btn img {
    margin-right: 3px;
}

.header__covid__btn span {
    margin-left: 4px;
}

.btn {
    background: hsl(213, 92%, 25%);
}

@media(min-width: 1100px) {
    .header__links {
        align-items: center;
    }

    .header__btn__links {
        align-items: center;
        margin-left: 3rem;
    }
}

@media(max-width: 900px) {
    header {
        padding-bottom: 4rem;
    }

    .header__logo {
        height: 50px;
    }
}

@media(max-width: 1100px) {
    .header__links {
        position: absolute;
        z-index: 800;
        height: 100vh;
        flex-direction: column;
        background: hsl(0, 0%, 100%, .9);
        backdrop-filter: blur(5px);
        top: 100%;
        right: -150%;
        width: clamp(230px, 100vw, 220px);
        transition: all .3s ease;
        justify-content: left;
        padding: 1rem 0;
    }

    .show {
        right: 0;
        animation: show;
        animation-duration: .5s;
        transition: all .3s ease;
    }

    .active {
        background: hsl(213, 92%, 25%, .1);
    }

    .active::after {
        transform: rotate(90deg);
        top: 1.16rem;
        left: -1rem;
        max-width: 38px;
        animation: none;
    }

    .header__links .pages__links,
    .header__links .header__btn__links {
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .header__covid__btn img {
        margin-bottom: -3px;
    }

    .header__covid__btn,
    .header__appointment__btn {
        display: block;
        margin-left: 2rem;
    }

    .header__links__toggler {
        display: block;
        cursor: pointer;
        padding: 5px 0;
        position: relative;
    }

    .header__links__toggler span {
        display: block;
        background: hsl(213, 92%, 25%);
        height: 2px;
        width: 1.7rem;
        margin: 6px 0;
        transition: all .3s ease;
        border-radius: 10px;
    }

    .rf {
        transform: rotate(45deg);
        position: absolute;
        top: 5px;
    }

    .dn {
        opacity: 0;
    }

    .rb {
        transform: rotate(-45deg);
        position: absolute;
        bottom: 4px;
    }

    .header__links .pages__links a {
        margin: 2px 0;
        padding: .7rem 2rem;
        width: 100%;
    }
}

@keyframes show {
    from {
        opacity: .5;
        transform: scale(.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}